import * as Sentry from "@sentry/nextjs";
import {ScopeContext} from "@sentry/types/types/scope";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import EnvUtils from "@/src/core/app/utils/env";
import UserUtils from "@/src/core/app/utils/user";

const SentryUtils = {
  isEnabled: () : boolean => {
    return EnvUtils.getBoolValue(process.env.NEXT_PUBLIC_SENTRY_ENABLED, false);
  },
  init: () => {
    if (!SentryUtils.isEnabled()) {
      console.log('Sentry -> NOT enabled');
      return;
    }

    Sentry.init({
      dsn: EnvUtils.getStringValue(process.env.NEXT_PUBLIC_SENTRY_DSN, ''),
      tracesSampleRate: 1.0,
      environment: EnvUtils.getStringValue(process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT, 'develop'),
    });
  },

  captureMessage: (message: string, captureContext?: Partial<ScopeContext>) => {
    if (!SentryUtils.isEnabled()) {
      console.log(`Sentry -> ${message}`, captureContext);
      return;
    }

    Sentry.captureMessage(message, captureContext);
  },

  captureException: (exception: any, captureContext?: Partial<ScopeContext>) => {
    if (!SentryUtils.isEnabled()) {
      console.log(`Sentry -> ${exception}`, captureContext);
      return;
    }

    Sentry.captureException(exception, captureContext);
  },
  logNetworkError: (message: string, networkError: NetworkResponseError, captureContext?: Partial<ScopeContext>) => {
    const extra = captureContext?.extra || {};
    extra['network_error_type'] = networkError.type;
    extra['network_error_data'] = networkError.data;
    extra['user_cookie_is_set'] = UserUtils.hasCookie();
    SentryUtils.captureMessage(
      `${message}: ${networkError.type}`,
      {
      ...captureContext,
      extra,
    });
  },
};

export default SentryUtils;
