import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {NetworkResponse, NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import SentryUtils from "@/src/common/utils/sentry";
import Cookies from "js-cookie";
import {CookieUserData} from "@/src/core/app/domain/@types/CookieUserData";
import {
  UserSessionRegenerateSuccessOutput
} from "@/src/core/app/domain/user/@types/Output/UserSessionRegenerateSuccessOutput";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {T_SitePageUrls} from "@/src/core/app/domain/models/site/T_SitePageUrls";
import {UserLostPasswordSuccessOutput} from "@/src/core/app/domain/user/@types/Output/UserLostPasswordSuccessOutput";
import {UserLostPasswordErrorOutput} from "@/src/core/app/domain/user/@types/Output/UserLostPasswordErrorOutput";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {
  UserLostPasswordResetErrorOutput
} from "@/src/core/app/domain/user/@types/Output/UserLostPasswordResetErrorOutput";
import {UserLostPasswordResetError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserLostPasswordResetError";

export const USER_DATA_COOKIE_NAME = 'he_session_front';

const UserUtils = {
  getGenderLabel: (key: string) : string => {
    if (key === '') {
      return '-';
    }
    const options = UserUtils.getGenderOptions();
    return options.find(item => item.value === key)?.label as string;
  },
  getGenderOptions: () => [
    {
      value: "male",
      label: "user_utils.gender.male"
    },
    {
      value: "female",
      label: "user_utils.gender.female"
    }
  ],
  getTripStyleLabel: (key: string) : string => {
    const options = UserUtils.getTripStyleOptions();
    return options.find(item => item.value === key)?.label as string;
  },
  getTripStyleOptions: () => [
    {
      value: "alone",
      label: "user_utils.trip_style.alone"
    },
    {
      value: "romantic",
      label: "user_utils.trip_style.romantic"
    },
    {
      value: "family",
      label: "user_utils.trip_style.family"
    },
    {
      value: "friends",
      label: "user_utils.trip_style.friends"
    },
  ],

  goToUserHome: (sitePageUrls: T_SitePageUrls) => {
    window.location.href = sitePageUrls.my_profile_page_url;
  },

  goToLoginPage: (sitePageUrls: T_SitePageUrls) => {
    window.location.href = sitePageUrls.elba_fidelity_page_url;
  },

  hasCookie: () : boolean => {
    const cookieValue = Cookies.get(USER_DATA_COOKIE_NAME);
    return cookieValue !== undefined;
  },

  getUserDataFromCookie: () : CookieUserData | null => {
    const cookieValue = Cookies.get(USER_DATA_COOKIE_NAME);
    if (cookieValue === undefined) {
      return null;
    }
    return JSON.parse(cookieValue) as CookieUserData;
  },
  loadUser: async () : Promise<UserData | null> => {
    const userData = UserUtils.getUserDataFromCookie();
    if (userData === null) {
      return null;
    }
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    const userDataResponse = await api.getUserData(userData.uid);

    if (!userDataResponse.isOk) {
      SentryUtils.logNetworkError(`UserUtils.loadUser failed`, userDataResponse.error as NetworkResponseError);
      return null;
    }
    return userDataResponse.data as UserData;
  },
  regenerateSession: async () : Promise<UserSessionRegenerateSuccessOutput | null> => {
    const userData = UserUtils.getUserDataFromCookie();
    if (userData === null) {
      return null;
    }

    const api = locator.get<UserApiService>(TYPES.UserApiService);
    const response = await api.userSessionRegenerate(CMS_AuthUtils.getAuthHeadersBrowser());
    if (!response.isOk) {
      SentryUtils.logNetworkError(`UserUtils.regenerateSession failed`, response.error as NetworkResponseError);
      return null;
    }

    return response.data as UserSessionRegenerateSuccessOutput;
  },

  lostPassword: async(appInfo: AppInfo, email: string) : Promise<NetworkResponse<UserLostPasswordSuccessOutput, UserLostPasswordErrorOutput>> => {
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.lostPassword(appInfo, email);
  },

  lostPasswordReset: async(
    appInfo: AppInfo,
    username: string,
    tempPassword: string,
    newPassword: string
  ) : Promise<NetworkResponse<UserLostPasswordSuccessOutput, UserLostPasswordErrorOutput>> => {
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.lostPasswordReset(appInfo, username, tempPassword, newPassword);
  },

  getLostPasswordResetNetworkErrorKey: (networkError: NetworkResponseError<UserLostPasswordResetErrorOutput>) : string | undefined => {
    if (networkError.data.message === 'This account is blocked or has not been activated yet.') {
      return UserLostPasswordResetError.ACCOUNT_BLOCKED;
    }
    if (networkError.data.message === 'The recovery password is not valid.') {
      return UserLostPasswordResetError.TOKEN_INVALID;
    }
    if (networkError.data.message === 'No valid temp password request.') {
      return UserLostPasswordResetError.TOKEN_EXPIRED;
    }
  }
};

export default UserUtils;
