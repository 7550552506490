/* eslint-disable @typescript-eslint/no-var-requires */
import "@/src/ui/styles/style.scss";
import "slick-carousel/slick/slick.css";
import "@/src/ui/styles/slick-theme.scss";

import type { AppProps } from "next/app";
import React from "react";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";

// Conditionally inject axe into the page.
// This only happens outside of production and in a browser (not SSR).
if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  // const ReactDOM = require("react-dom");
  // const axe = require("@axe-core/react");
  // axe(React, ReactDOM, 1000);
}

function MyApp({ Component, pageProps, router }: AppProps) {
  const langcode = router.locale?.toUpperCase() || 'ES';
  return (
    <>
      <Head>
        <title>hoteleselba.front</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=2"/>
        {/*{process.env.NODE_ENV === "production" && (*/}
        {/*  <meta httpEquiv="Content-Security-Policy" content="default-src 'self'; child-src 'none'; style-src 'unsafe-inline'; object-src 'none'" />*/}
        {/*)}*/}
        <meta httpEquiv="referrer" content="no-referrer, strict-origin-when-cross-origin"/>
        <meta name="facebook-domain-verification" content="rnrtp8zr400cpm1pikh5obul5tyaul"/>
        <script type="text/javascript" dangerouslySetInnerHTML={{
          __html: `window.cookiebot_langcode = "${langcode}";`
        }} />
        <meta name="google-site-verification" content="61d9DieAfZUxJh6XY_aG5y3PhSu_2TTwI21tusuihSg"/>
        <script type="text/plain" data-cookieconsent="marketing" dangerouslySetInnerHTML={{
          __html: `
          /*  Facebook Pixel Code */
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '222318178512585');
          fbq('track', 'PageView');
          /* End Facebook Pixel Code */
          `
        }} />
        <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`} async
                defer></script>
        <script src='https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.js'></script>
        <link href='https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css' rel='stylesheet'/>
        <script src='/libraries/leaflet/leaflet.js'></script>
        <link href='/libraries/leaflet/leaflet.css' rel='stylesheet'/>
        <script src='/libraries/leaflet-gridLayer-googleMutant/Leaflet.GoogleMutant.js'></script>
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default appWithTranslation(MyApp);
