import {inject, injectable} from "inversify";
import {RestClient} from "@/src/common/network/rest/rest_client";
import type {IEnvVars} from "@/src/core/app/domain/@types/IEnvVars";
import {TYPES} from "@/src/core/app/ioc/types";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {ContactFormInput} from "@/src/core/app/domain/contact/@types/Input/ContactFormInput";
import {ContactFormSuccessOutput} from "@/src/core/app/domain/contact/@types/Output/ContactFormSuccessOutput";
import {ContactFormErrorOutput} from "@/src/core/app/domain/contact/@types/Output/ContactFormErrorOutput";
import {EventRequestFormInput} from "@/src/core/app/domain/event/@types/Input/EventRequestFormErrors";
import {EventRequestFormSuccessOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormSuccessOutput";
import {EventRequestFormErrorOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormErrorOutput";
import {JobOfferFormInput} from "@/src/core/app/domain/job-offer/@types/Input/JobOfferFormInput";
import {JobOfferFormErrorOutput} from "@/src/core/app/domain/job-offer/@types/Output/JobOfferFormErrorOutput";
import {JobOfferFormSuccessOutput} from "@/src/core/app/domain/job-offer/@types/Output/JobOfferFormSuccessOutput";
import {JobRequestFormInput} from "@/src/core/app/domain/job-request/@types/Input/JobRequestFormInput";
import {JobRequestFormErrorOutput} from "@/src/core/app/domain/job-request/@types/Output/JobRequestFormErrorOutput";
import {JobRequestFormSuccessOutput} from "@/src/core/app/domain/job-request/@types/Output/JobRequestFormSuccessOutput";
import {CMS_AuthHeaders} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthHeaders";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";

@injectable()
export class FormsApiService {
  private readonly client: RestClient;

  constructor(@inject(TYPES.IEnvVars) envVars: IEnvVars) {
    this.client = new RestClient(envVars.publicUserApiUrl, {
      timeout: envVars.publicUserApiTimeout
    });
  }

  getUrl(path: string, appInfo?: AppInfo) {
    const langcode = appInfo ? appInfo.langcode : 'en';
    return `/${langcode}${path}`;
  }
  async contactForm(
    appInfo: AppInfo,
    firstName: string,
    lastName: string,
    email: string,
    hotelCode: string,
    department: string,
    body: string,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<ContactFormSuccessOutput, ContactFormErrorOutput>> {
    const url = this.getUrl('/api/1.0/contact-form', appInfo);
    return NetworkUtils.run<ContactFormSuccessOutput, ContactFormErrorOutput>(async () => {
      const res = await this.client.post<
        ContactFormSuccessOutput,
        ContactFormInput
      >(url, {
        data: {
          first_name: firstName,
          last_name: lastName,
          email,
          hotel_code: hotelCode,
          department,
          body,
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders),
        params: {
          _format: 'json'
        },
      });
      return res.data;
    });
  }

  async eventRequestForm(
    appInfo: AppInfo,
    input: EventRequestFormInput,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<EventRequestFormSuccessOutput, EventRequestFormErrorOutput>> {
    const url = this.getUrl('/api/1.0/event-request-form', appInfo);
    return NetworkUtils.run<EventRequestFormSuccessOutput, EventRequestFormErrorOutput>(async () => {
      const res = await this.client.post<
        EventRequestFormSuccessOutput,
        EventRequestFormInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders),
        params: {
          _format: 'json'
        },
      });
      return res.data;
    });
  }

  async jobOfferForm(
    appInfo: AppInfo,
    jobOffer: string,
    cvFileName: string,
    cvFileData: string,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<JobOfferFormSuccessOutput, JobOfferFormErrorOutput>> {
    const url = this.getUrl('/api/1.0/job-offer-form', appInfo);
    return NetworkUtils.run<JobOfferFormSuccessOutput, JobOfferFormErrorOutput>(async () => {
      const res = await this.client.post<
        JobOfferFormSuccessOutput,
        JobOfferFormInput
      >(url, {
        data: {
          job_offer: jobOffer,
          cv: {
            name: cvFileName,
            data: cvFileData,
          },
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders),
        params: {
          _format: 'json'
        },
      });
      return res.data;
    });
  }

  async jobRequestForm(
    appInfo: AppInfo,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    hotelCode: string,
    jobType: string,
    cvFileName: string,
    cvFileData: string,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<JobRequestFormSuccessOutput, JobRequestFormErrorOutput>> {
    const url = this.getUrl('/api/1.0/job-request-form', appInfo);
    return NetworkUtils.run<JobRequestFormSuccessOutput, JobRequestFormErrorOutput>(async () => {
      const res = await this.client.post<
        JobRequestFormSuccessOutput,
        JobRequestFormInput
      >(url, {
        data: {
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          hotel_code: hotelCode,
          job_type: jobType,
          cv: {
            name: cvFileName,
            data: cvFileData,
          },
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders),
        params: {
          _format: 'json'
        },
      });
      return res.data;
    });
  }
}
