import {inject, injectable} from "inversify";
import {RestClient} from "@/src/common/network/rest/rest_client";
import type {IEnvVars} from "@/src/core/app/domain/@types/IEnvVars";
import {TYPES} from "@/src/core/app/ioc/types";
import {NetworkResponse, NetworkResponseError, NetworkResponseErrorType} from "@/src/common/network/NetworkResponse";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import {UserRegisterInput} from "@/src/core/app/domain/user/@types/Input/UserRegisterInput";
import {AxiosError} from "axios";
import {UserRegisterError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserRegisterError";
import {UserRegisterErrorOutput} from "@/src/core/app/domain/user/@types/Output/UserRegisterErrorOutput";
import {UserLoginSuccessOutput} from "@/src/core/app/domain/user/@types/Output/UserLoginSuccessOutput";
import {UserLoginErrorOutput} from "@/src/core/app/domain/user/@types/Output/UserLoginErrorOutput";
import {UserLoginError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserLoginError";
import {UserDataSuccessOutput} from "@/src/core/app/domain/user/@types/Output/UserDataSuccessOutput";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import {
  UserSessionRegenerateSuccessOutput
} from "@/src/core/app/domain/user/@types/Output/UserSessionRegenerateSuccessOutput";
import {UserSessionRegenerateInput} from "@/src/core/app/domain/user/@types/Input/UserSessionRegenerateInput";
import {CMS_AuthHeaders} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthHeaders";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {UserUpdateInput} from "@/src/core/app/domain/user/@types/Input/UserUpdateInput";
import {UserPasswordChangeInput} from "@/src/core/app/domain/user/@types/Input/UserPasswordChangeInput";
import {UserPasswordChangeError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserPasswordChangeError";
import {UserPasswordChangeErrorOutput} from "@/src/core/app/domain/user/@types/Output/UserPasswordChangeErrorOutput";
import {UserTmsInfoSuccessOutput} from "@/src/core/app/domain/user/@types/Output/UserTmsInfoSuccessOutput";
import {UserTmsInfoInput} from "@/src/core/app/domain/user/@types/Input/UserTmsInfoInput";
import {UserTmsAssignInput} from "@/src/core/app/domain/user/@types/Input/UserTmsAssignInput";
import {UserTmsAssignSuccessOutput} from "@/src/core/app/domain/user/@types/Output/UserTmsAssignSuccessOutput";
import {UserTmsAssignErrorOutput} from "@/src/core/app/domain/user/@types/Output/UserTmsAssignErrorOutput";
import {UserLostPasswordSuccessOutput} from "@/src/core/app/domain/user/@types/Output/UserLostPasswordSuccessOutput";
import {UserLostPasswordInput} from "@/src/core/app/domain/user/@types/Input/UserLostPasswordInput";
import {UserLostPasswordErrorOutput} from "@/src/core/app/domain/user/@types/Output/UserLostPasswordErrorOutput";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {
  UserLostPasswordResetSuccessOutput
} from "@/src/core/app/domain/user/@types/Output/UserLostPasswordResetSuccessOutput";
import {
  UserLostPasswordResetErrorOutput
} from "@/src/core/app/domain/user/@types/Output/UserLostPasswordResetErrorOutput";
import {UserLostPasswordResetInput} from "@/src/core/app/domain/user/@types/Input/UserLostPasswordResetInput";
import {ElbaMediaSuccessOutput} from "@/src/core/app/domain/elba-media/@types/Output/ElbaMediaSuccessOutput";
import {ElbaMediaErrorOutput} from "@/src/core/app/domain/elba-media/@types/Output/ElbaMediaErrorOutput";
import UrlUtils from "@/src/core/app/utils/url";
import {ElbaMediaGolfSuccessOutput} from "@/src/core/app/domain/elba-media/@types/Output/ElbaMediaGolfSuccessOutput";
import {ElbaMediaGolfErrorOutput} from "@/src/core/app/domain/elba-media/@types/Output/ElbaMediaGolfErrorOutput";
import {ElbaMediaGolfInput} from "@/src/core/app/domain/elba-media/@types/Input/ElbaMediaGolfInput";
import SentryUtils from "@/src/common/utils/sentry";

@injectable()
export class UserApiService {
  private readonly client: RestClient;

  constructor(@inject(TYPES.IEnvVars) envVars: IEnvVars) {
    this.client = new RestClient(envVars.publicUserApiUrl, {
      timeout: envVars.publicUserApiTimeout
    });
  }

  getUrl(path: string, appInfo?: AppInfo, params?: Record<string, any>) {
    const langcode = appInfo ? appInfo.langcode : 'en';
    const url = `/${langcode}${path}`;
    return UrlUtils.addParamsToUrl(url, params);
  }

  static userRegisterErrorAnalyzerFn(e: AxiosError<UserRegisterErrorOutput>) : NetworkResponseError<UserRegisterError> {
    if (e.response === undefined) {
      return NetworkUtils.getClientErrorResponse(e);
    }

    if (e.response.status === 422) {
      const message = e.response.data.message;
      const invalidEmail = message.search(/The email address [a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])? is already taken/g) !== -1;
      if (invalidEmail) {
        return {
          type: NetworkResponseErrorType.SERVER_EXPECTED,
          data: UserRegisterError.EMAIL_ALREADY_REGISTERED,
        }
      }

      const passwordWeak = message.search(/password:error:password-too-weak/g) !== -1;
      if (passwordWeak) {
        return {
          type: NetworkResponseErrorType.SERVER_EXPECTED,
          data: UserRegisterError.PASSWORD_WEAK,
        }
      }

      return NetworkUtils.getServerUnexpectedErrorResponse(e);
    }

    return NetworkUtils.getServerUnexpectedErrorResponse(e);
  }
  static userLoginErrorAnalyzerFn(e: AxiosError<UserLoginErrorOutput>) : NetworkResponseError<UserLoginError> {
    if (e.response === undefined) {
      return NetworkUtils.getClientErrorResponse(e);
    }

    if (e.response.status === 400) {
      const message = e.response.data.message;
      const invalidCredentials = message.search(/Sorry, unrecognized username or password./g) !== -1;
      if (invalidCredentials) {
        return {
          type: NetworkResponseErrorType.SERVER_EXPECTED,
          data: UserLoginError.INVALID_CREDENTIALS,
        }
      }
      return NetworkUtils.getServerUnexpectedErrorResponse(e);
    }

    return NetworkUtils.getServerUnexpectedErrorResponse(e);
  }
  static userPasswordChangeErrorAnalyzerFn(e: AxiosError<UserPasswordChangeErrorOutput>) : NetworkResponseError<UserPasswordChangeError> {
    if (e.response === undefined) {
      return NetworkUtils.getClientErrorResponse(e);
    }

    if (e.response.status === 422) {
      const message = e.response.data.message;
      const invalidEmail = message.search(/Your current password is missing or incorrect/g) !== -1;
      if (invalidEmail) {
        return {
          type: NetworkResponseErrorType.SERVER_EXPECTED,
          data: UserPasswordChangeError.INVALID_CREDENTIALS,
        }
      }
      return NetworkUtils.getServerUnexpectedErrorResponse(e);
    }

    return NetworkUtils.getServerUnexpectedErrorResponse(e);
  }
  async userRegister(appInfo: AppInfo, input: UserRegisterInput) : Promise<NetworkResponse<any>> {
    const url = this.getUrl('/user/register', appInfo);
    const data = {
      "name": {
        "value": input.email,
      },
      "pass": {
        "value": input.password,
      },
      "mail": {
        "value": input.email
      },
      "preferred_langcode": {
        "value": input.langcode
      },
      "field_user_first_name": {
        "value": input.firstName
      },
      "field_user_last_name": {
        "value": input.lastName
      },
      "field_user_gender": {
        "value": input.gender
      },
      "field_user_phone_number": {
        "value": input.phoneNumber
      },
      "field_user_country": {
        "value": input.country
      },
      // "field_user_newsletter": input.newsletter.map(v => ({
      //   "target_id": v,
      // })),
      "field_user_trip_style": input.tripStyle.map(v => ({
        "value": v,
      }))
    };

    return NetworkUtils.run<any, UserRegisterError, UserRegisterErrorOutput>(async () => {
      const res = await this.client.post<
        any,
        any
      >(url, {
        data,
        params: {
          _format: 'json'
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return res.data;
    }, UserApiService.userRegisterErrorAnalyzerFn);
  }
  async userLogin(username: string, password: string): Promise<NetworkResponse<UserLoginSuccessOutput>> {
    const url = this.getUrl('/user/login');
    const data = {
      "name": username,
      "pass": password,
    };

    return NetworkUtils.run<UserLoginSuccessOutput, UserLoginError, UserLoginErrorOutput>(async () => {
      const res = await this.client.post<
        any,
        any
      >(url, {
        data,
        params: {
          _format: 'json'
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return res.data;
    }, UserApiService.userLoginErrorAnalyzerFn);
  }
  async userLogout(token: string): Promise<NetworkResponse> {
    const url = this.getUrl('/user/logout');
    const data = {};

    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        any,
        any
      >(url, {
        data,
        params: {
          _format: 'json',
          token,
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return res.data;
    });
  }

  getFieldValueOrDefault(data: any, key: string, defaultValue: any, elementKey = 'value') : any {
    try {
      if (data[key].length === 0) {
        return defaultValue;
      }
      return data[key][0][elementKey];
    }
    catch (e) {
      SentryUtils.captureMessage(`UserApiService.getFieldValueOrDefault FAILED: ${key}`);
      return defaultValue;
    }
  }

  convertUserDataSuccessOutputToUserData(data: UserDataSuccessOutput) : UserData {
    try {
      return {
        id: this.getFieldValueOrDefault(data, 'uid', ''),
        langcode: this.getFieldValueOrDefault(data, 'langcode', ''),
        firstName: this.getFieldValueOrDefault(data, 'field_user_first_name', ''),
        lastName: this.getFieldValueOrDefault(data, 'field_user_last_name', ''),
        email: this.getFieldValueOrDefault(data, 'mail', ''),
        gender: this.getFieldValueOrDefault(data, 'field_user_gender', ''),
        phoneNumber: this.getFieldValueOrDefault(data, 'field_user_phone_number', ''),
        country: this.getFieldValueOrDefault(data, 'field_user_country', '', 'target_uuid'),
        tripStyle: data.field_user_trip_style.map(item => item.value),
        tmsClientId: this.getFieldValueOrDefault(data, 'field_user_tms_client_id', null),
        elbaMediaRoles: data.cpt_elba_media_roles.map(item => item.value),
      }
    }
    catch (e) {
      SentryUtils.captureMessage('UserApiService.convertUserDataSuccessOutputToUserData FAILED');
      SentryUtils.captureException(e);
      throw e;
    }
  }
  async getUserData(userId: string) : Promise<NetworkResponse<UserData>> {
    const url = this.getUrl(`/user/${userId}`);
    return NetworkUtils.run<UserData>(async () => {
      const res = await this.client.get<UserDataSuccessOutput>(url, {
        params: {
          _format: 'json'
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return this.convertUserDataSuccessOutputToUserData(res.data);
    });
  }
  async userSessionRegenerate(authHeaders?: CMS_AuthHeaders) : Promise<NetworkResponse<UserSessionRegenerateSuccessOutput>> {
    const url = this.getUrl('/api/1.0/user/session/regenerate');
    return NetworkUtils.run<UserSessionRegenerateSuccessOutput>(async () => {
      const res = await this.client.post<
        UserSessionRegenerateSuccessOutput,
        UserSessionRegenerateInput
      >(url, {
        data: {},
        params: {
          _format: 'json'
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    });
  }
  async userUpdate(userId: string, input: UserUpdateInput, authHeaders?: CMS_AuthHeaders) : Promise<NetworkResponse<UserData>> {
    const url = this.getUrl(`/user/${userId}`);
    const data = {
      "preferred_langcode": {
        "value": input.langcode
      },
      "field_user_first_name": {
        "value": input.firstName
      },
      "field_user_last_name": {
        "value": input.lastName
      },
      "field_user_gender": {
        "value": input.gender
      },
      "field_user_phone_number": {
        "value": input.phoneNumber
      },
      "field_user_country": {
        "value": input.country
      },
      // "field_user_newsletter": input.newsletter.map(v => ({
      //   "target_id": v,
      // })),
      "field_user_trip_style": input.tripStyle.map(v => ({
        "value": v,
      }))
    };

    return NetworkUtils.run<UserData>(async () => {
      const res = await this.client.patch<
        UserDataSuccessOutput,
        any
      >(url, {
        data,
        params: {
          _format: 'json'
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return this.convertUserDataSuccessOutputToUserData(res.data);
    });
  }
  async userPasswordChange(userId: string, input: UserPasswordChangeInput, authHeaders?: CMS_AuthHeaders) : Promise<NetworkResponse<UserData>> {
    const url = this.getUrl(`/user/${userId}`);
    const data = {
      "pass": [{
        "existing": input.currentPassword,
        "value": input.newPassword
      }]
    };

    return NetworkUtils.run<UserData, UserPasswordChangeError>(async () => {
      const res = await this.client.patch<
        UserDataSuccessOutput,
        any
      >(url, {
        data,
        params: {
          _format: 'json'
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return this.convertUserDataSuccessOutputToUserData(res.data);
    }, UserApiService.userPasswordChangeErrorAnalyzerFn);
  }
  async userSessionToken(): Promise<NetworkResponse<string>> {
    const url = this.getUrl('/session/token');

    return NetworkUtils.run(async () => {
      const res = await this.client.get<string>(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return res.data;
    });
  }
  async userTmsInfo(appInfo: AppInfo, authHeaders?: CMS_AuthHeaders) : Promise<NetworkResponse<UserTmsInfoSuccessOutput>> {
    const url = this.getUrl('/api/1.0/user/tms/info', appInfo);
    return NetworkUtils.run<UserTmsInfoSuccessOutput>(async () => {
      const res = await this.client.post<
        UserTmsInfoSuccessOutput,
        UserTmsInfoInput
      >(url, {
        data: {},
        params: {
          _format: 'json'
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    });
  }
  async userTmsAssign(clientId: string, authHeaders?: CMS_AuthHeaders) : Promise<NetworkResponse<UserTmsAssignSuccessOutput, UserTmsAssignErrorOutput>> {
    const url = this.getUrl('/api/1.0/user/tms/assign');
    return NetworkUtils.run<UserTmsAssignSuccessOutput, UserTmsAssignErrorOutput>(async () => {
      const res = await this.client.post<
        UserTmsAssignSuccessOutput,
        UserTmsAssignInput
      >(url, {
        data: {
          client_id: clientId,
        },
        params: {
          _format: 'json'
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    });
  }
  async lostPassword(appInfo: AppInfo, email: string) : Promise<NetworkResponse<UserLostPasswordSuccessOutput, UserLostPasswordErrorOutput>> {
    const url = this.getUrl('/user/lost-password', appInfo);
    return NetworkUtils.run<UserLostPasswordSuccessOutput, UserLostPasswordErrorOutput>(async () => {
      const res = await this.client.post<
        UserLostPasswordSuccessOutput,
        UserLostPasswordInput
      >(url, {
        data: {
          mail: email,
          lang: appInfo.langcode,
        },
        params: {
          _format: 'json'
        },
      });
      return res.data;
    });
  }
  async lostPasswordReset(
    appInfo: AppInfo,
    username: string,
    tempPassword: string,
    newPassword: string
  ) : Promise<NetworkResponse<UserLostPasswordResetSuccessOutput, UserLostPasswordResetErrorOutput>> {
    const url = this.getUrl('/user/lost-password-reset', appInfo);
    return NetworkUtils.run<UserLostPasswordResetSuccessOutput, UserLostPasswordResetErrorOutput>(async () => {
      const res = await this.client.post<
        UserLostPasswordResetSuccessOutput,
        UserLostPasswordResetInput
      >(url, {
        data: {
          name: username,
          temp_pass: tempPassword,
          new_pass: newPassword,
        },
        params: {
          _format: 'json'
        },
      });
      return res.data;
    });
  }

  async getElbaMedia(
    appInfo: AppInfo,
    authHeaders?: CMS_AuthHeaders,
    page?: string,
  ) : Promise<NetworkResponse<ElbaMediaSuccessOutput, ElbaMediaErrorOutput>> {
    const url = this.getUrl('/api/1.0/elba-media', appInfo, {page});
    return NetworkUtils.run<ElbaMediaSuccessOutput, ElbaMediaErrorOutput>(async () => {
      const res = await this.client.post<
        ElbaMediaSuccessOutput,
        any
      >(url, {
        data: {},
        params: {
          _format: 'json'
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    });
  }

  async getElbaMediaGolf(
    appInfo: AppInfo,
    authHeaders?: CMS_AuthHeaders,
    page?: string,
    path?: string
  ) : Promise<NetworkResponse<ElbaMediaGolfSuccessOutput, ElbaMediaGolfErrorOutput>> {
    const url = this.getUrl('/api/1.0/elba-media-golf', appInfo, {page});
    const data : ElbaMediaGolfInput = {};
    if (path) {
      data['path'] = path;
    }
    return NetworkUtils.run<ElbaMediaGolfSuccessOutput, ElbaMediaGolfErrorOutput>(async () => {
      const res = await this.client.post<
        ElbaMediaGolfSuccessOutput,
        ElbaMediaGolfInput
      >(url, {
        data,
        params: {
          _format: 'json'
        },
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    });
  }
}
